import {Model} from "../vuemc";

export default class OrganisationRegister extends Model
{
    defaults() {
        return {
            name:         null,
            password:     null,
            role:         null,
            timezone:     null,
            region:       null,
            terms:        false,
            captchaToken: null,
        }
    }
}
